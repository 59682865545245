
import { defineComponent } from 'vue';

import NavigationBar from './components/NavigationBar/_index.vue';

import { options, action } from './static/config/map';

export default defineComponent({
  name: 'App',
  components: {
    NavigationBar,
  },
  setup() {
    return {
      options,
      action,
    };
  },
});
