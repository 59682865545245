
import { defineComponent, PropType } from 'vue';

import { NavigationLink } from '../../../types';

export default defineComponent({
  props: {
    link: {
      type: Object as PropType<NavigationLink>,
      required: true,
    },
  },
});
