import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderSlot as _renderSlot, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "m-auto rounded-full max-w-fit" }
const _hoisted_2 = { class: "flex flex-col gap-8" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "m-auto" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "m-auto" }
const _hoisted_9 = { class: "relative inline-block top-px" }
const _hoisted_10 = {
  key: 0,
  class: "bg-gray-300 mt-2 py-4"
}
const _hoisted_11 = ["href"]
const _hoisted_12 = { class: "flex flex-col gap-4" }
const _hoisted_13 = {
  key: 1,
  class: "bg-gray-400 inset-0 top-16 opacity-75 z-20"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_close = _resolveComponent("svg-close")!
  const _component_svg_hamburger = _resolveComponent("svg-hamburger")!
  const _component_svg_chevron = _resolveComponent("svg-chevron")!
  const _component_OnClickOutside = _resolveComponent("OnClickOutside")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isMenuShown)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.hideMenu && _ctx.hideMenu(...args)), ["prevent"]))
          }, [
            _createVNode(_component_svg_close)
          ]))
        : (_openBlock(), _createElementBlock("button", {
            key: 1,
            onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.showMenu && _ctx.showMenu(...args)), ["prevent"]))
          }, [
            _createVNode(_component_svg_hamburger)
          ]))
    ]),
    (_ctx.isMenuShown)
      ? (_openBlock(), _createBlock(_component_OnClickOutside, {
          key: 0,
          as: "div",
          class: "fixed top-16 bottom-0 left-0 right-16 flex flex-col justify-between py-8 bg-gray-100",
          onTrigger: _ctx.hideMenu
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, i) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                  (option.type === 'link')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createElementVNode("button", {
                          onClick: _withModifiers(($event: any) => (_ctx.toggleNavOption({ option, i })), ["prevent"])
                        }, [
                          _createElementVNode("span", _hoisted_5, _toDisplayString(option.label), 1)
                        ], 8, _hoisted_4)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createVNode(_component_OnClickOutside, { onTrigger: _ctx.resetNavOptions }, {
                          default: _withCtx(() => [
                            _createElementVNode("button", {
                              onClick: _withModifiers(($event: any) => (_ctx.toggleNavOption({ option, i })), ["prevent"])
                            }, [
                              _createElementVNode("span", _hoisted_8, [
                                _createTextVNode(_toDisplayString(option.label) + " ", 1),
                                _createElementVNode("div", _hoisted_9, [
                                  _createVNode(_component_svg_chevron, {
                                    up: _ctx.currNavOptionId === i
                                  }, null, 8, ["up"])
                                ])
                              ])
                            ], 8, _hoisted_7),
                            (_ctx.currNavOptionId === i)
                              ? (_openBlock(), _createElementBlock("ul", _hoisted_10, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(option.links, (link, j) => {
                                    return (_openBlock(), _createElementBlock("li", {
                                      key: `${i}:${j}`,
                                      class: "flex rounded-sm hover:bg-gray-300 h-full p-2"
                                    }, [
                                      _createElementVNode("a", {
                                        class: "m-auto px-2",
                                        href: link.href
                                      }, _toDisplayString(link.label), 9, _hoisted_11)
                                    ]))
                                  }), 128))
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["onTrigger"])
                      ]))
                ], 64))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_12, [
              _renderSlot(_ctx.$slots, "social"),
              _renderSlot(_ctx.$slots, "action")
            ])
          ]),
          _: 3
        }, 8, ["onTrigger"]))
      : _createCommentVNode("", true),
    (_ctx.isMenuShown)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13))
      : _createCommentVNode("", true)
  ]))
}