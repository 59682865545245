
import { defineComponent, PropType } from 'vue';

import { NavigationLink, NavigationOption } from '../../types';

import NavHamburgerMenu from './HamburgerMenu.vue';
import NavFullScreenMenu from './FullScreenMenu.vue';
import NavSocialMenu from './SocialMenu.vue';

export default defineComponent({
  props: {
    options: {
      type: Object as PropType<NavigationOption[]>,
      required: true,
    },
    action: {
      type: Object as PropType<NavigationLink>,
      required: true,
    },
  },
  components: {
    NavHamburgerMenu,
    NavFullScreenMenu,
    NavSocialMenu,
  },
});
