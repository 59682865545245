
import SvgLinkedIn from '../../static/svg/LinkedIn.vue';
import SvgGitHub from '../../static/svg/GitHub.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    SvgLinkedIn,
    SvgGitHub,
  },
});
