
import { defineComponent, PropType, ref } from 'vue';
import { NavigationOption } from '../../types';

import useNavigation from '../../composables/useNavigation';
import { OnClickOutside } from '@vueuse/components';

import SvgHamburger from '../../static/svg/Hamburger.vue';
import SvgClose from '../../static/svg/Close.vue';
import SvgChevron from '../../static/svg/Chevron.vue';

export default defineComponent({
  props: {
    options: {
      type: Object as PropType<NavigationOption[]>,
      required: true,
    },
  },
  components: {
    OnClickOutside,
    SvgHamburger,
    SvgClose,
    SvgChevron,
  },
  setup() {
    const isMenuShown = ref(false);

    const showMenu = function () {
      isMenuShown.value = true;
    };
    const hideMenu = function () {
      isMenuShown.value = false;
    };

    const { currNavOptionId, toggleNavOption, resetNavOptions } = useNavigation(
      'xl',
      [hideMenu]
    );

    return {
      currNavOptionId,
      toggleNavOption,
      resetNavOptions,
      isMenuShown,
      showMenu,
      hideMenu,
    };
  },
});
