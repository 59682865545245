import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-auto" }
const _hoisted_2 = { class: "my-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_linked_in = _resolveComponent("svg-linked-in")!
  const _component_svg_git_hub = _resolveComponent("svg-git-hub")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_svg_linked_in)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_svg_git_hub)
    ])
  ]))
}