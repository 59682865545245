import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "m-auto" }
const _hoisted_2 = { class: "relative inline-block top-px" }
const _hoisted_3 = { class: "container mx-auto rounded-b-md grid grid-cols-12 px-16 py-8 bg-gray-200 h-full shadow-2xl" }
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_chevron = _resolveComponent("svg-chevron")!
  const _component_OnClickOutside = _resolveComponent("OnClickOutside")!

  return (_openBlock(), _createBlock(_component_OnClickOutside, {
    onTrigger: _ctx.emitGroupUnselect,
    as: "div",
    class: _normalizeClass(["h-full px-1 border-b-2", { 'border-gray-100': !_ctx.selected, 'border-gray-400': _ctx.selected }])
  }, {
    default: _withCtx(() => [
      _createElementVNode("button", {
        class: "h-full flex",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('group:select')), ["prevent"]))
      }, [
        _createElementVNode("span", _hoisted_1, [
          _createTextVNode(_toDisplayString(_ctx.group.label) + " ", 1),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_svg_chevron, { up: _ctx.selected }, null, 8, ["up"])
          ])
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["fixed inset-x-0 top-16 mx-16", { hidden: !_ctx.selected }])
      }, [
        _createElementVNode("ul", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.group.links, (link, j) => {
            return (_openBlock(), _createElementBlock("li", {
              key: `grp-drpdown.${j}`,
              class: "hidden xl:flex rounded-sm xl:col-3-to-9 bg-gray-200 hover:bg-gray-300 w-fit h-full p-2"
            }, [
              _createElementVNode("a", {
                class: "my-auto px-2",
                href: link.href
              }, _toDisplayString(link.label), 9, _hoisted_4)
            ]))
          }), 128))
        ])
      ], 2)
    ]),
    _: 1
  }, 8, ["onTrigger", "class"]))
}