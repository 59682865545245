import { NavigationLink, NavigationGroup } from '../../types';

const options: Array<NavigationLink | NavigationGroup> = [
  {
    type: 'link',
    label: 'Home',
    href: '#',
  },
  {
    type: 'group',
    label: 'Articles',
    links: [
      {
        type: 'link',
        label: 'Learn JavaScript',
        href: '#',
      },
    ],
  },
  {
    type: 'link',
    label: 'Random',
    href: '#',
  },
];

const action: NavigationLink = {
  type: 'link',
  label: 'about me',
  href: '#',
};

export { options, action };
