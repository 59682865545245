
import { defineComponent, PropType } from 'vue';

import useNavigation from '../../composables/useNavigation';

import { NavigationOption } from '../../types';

import NavLink from './subcomponents/Link.vue';
import NavGroupDropdown from './subcomponents/GroupDropdown.vue';

export default defineComponent({
  props: {
    options: {
      type: Object as PropType<NavigationOption[]>,
      required: true,
    },
  },
  components: {
    NavLink,
    NavGroupDropdown,
  },
  setup() {
    const { currNavOptionId, toggleNavOption, resetNavOptions } =
      useNavigation('xl');

    return { currNavOptionId, toggleNavOption, resetNavOptions };
  },
});
