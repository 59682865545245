import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "m-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: "h-full px-2 flex border-b-4 border-gray-100",
    href: _ctx.link.href,
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('link:select')), ["prevent"]))
  }, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.link.label), 1)
  ], 8, _hoisted_1))
}