
import { defineComponent, PropType } from 'vue';
import { NavigationGroup } from '../../../types';

import { OnClickOutside } from '@vueuse/components';
import SvgChevron from '../../../static/svg/Chevron.vue';

export default defineComponent({
  props: {
    group: {
      type: Object as PropType<NavigationGroup>,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    OnClickOutside,
    SvgChevron,
  },
  setup(props, ctx) {
    const emitGroupUnselect = () =>
      props.selected && ctx.emit('group:unselect');

    return { emitGroupUnselect };
  },
});
