import { ref, watch } from 'vue';

import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';

import { NavigationOption } from '../types';

type ToggleNavOption = {
  option: NavigationOption;
  i: number;
};

type Breakpoint = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

export default function (breakpoint: Breakpoint, callbacks?: Function[]) {
  const currNavOptionId = ref(0);

  const toggleNavOption = ({ option, i }: ToggleNavOption) => {
    if (option.type === 'link') {
      currNavOptionId.value = i;
    } else if (currNavOptionId.value !== i) {
      currNavOptionId.value = i;
    } else {
      resetNavOptions();
    }
  };

  const resetNavOptions = () => {
    currNavOptionId.value = -1;
  };

  const bp = useBreakpoints(breakpointsTailwind).smaller(breakpoint);

  watch(bp, (_new) => {
    if (callbacks) {
      callbacks.forEach((cb) => cb());
    }
    if (!_new) {
      resetNavOptions();
    }
  });

  return { currNavOptionId, toggleNavOption, resetNavOptions };
}
