import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nav_link = _resolveComponent("nav-link")!
  const _component_nav_group_dropdown = _resolveComponent("nav-group-dropdown")!

  return (_openBlock(), _createElementBlock("ul", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, i) => {
      return (_openBlock(), _createElementBlock("li", {
        key: i,
        class: "my-auto h-full"
      }, [
        (option.type === 'link')
          ? (_openBlock(), _createBlock(_component_nav_link, {
              key: 0,
              link: option,
              "onLink:select": ($event: any) => (_ctx.toggleNavOption({ option, i }))
            }, null, 8, ["link", "onLink:select"]))
          : (_openBlock(), _createBlock(_component_nav_group_dropdown, {
              key: 1,
              group: option,
              selected: _ctx.currNavOptionId === i,
              "onGroup:select": ($event: any) => (_ctx.toggleNavOption({ option, i })),
              "onGroup:unselect": _ctx.resetNavOptions
            }, null, 8, ["group", "selected", "onGroup:select", "onGroup:unselect"]))
      ]))
    }), 128))
  ]))
}