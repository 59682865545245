<template>
  <svg
    :class="`inline w-3 h-3 relative ${offset}`"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg">
    <path
      v-if="up"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M5 15l7-7 7 7" />
    <path
      v-else
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M19 9l-7 7-7-7" />
  </svg>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'SvgChevron',
  props: {
    up: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const offset = computed(() => (props.up ? 'bottom-0.5' : 'bottom-0.5'));
    return { offset };
  },
};
</script>
